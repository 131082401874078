import { BaseMagazineInfo, MagazineInfo, MagazineInfoMulti, SoldSearch } from "./type";

export function isMagazine(magazine: BaseMagazineInfo): magazine is MagazineInfo {
    return (<MagazineInfo>magazine).token_ids !== undefined
}

export function isMagazineMulti(magazine: BaseMagazineInfo): magazine is MagazineInfoMulti {
    return (<MagazineInfoMulti>magazine).amount !== undefined
}

export function isSoldSearch(str: any): str is SoldSearch {
    return typeof str === 'string'  && ['Available', 'Sold out' , 'All'].includes(str)
}