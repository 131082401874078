



























































import { Component, Vue, Watch } from 'vue-property-decorator';
import ProductList from '@/components/ProductList.vue';
import RewardsIcon from '@/components/RewardsIcon.vue';
import { SoldSearch } from '../type'
import {isSoldSearch } from '../type_helper'
@Component({
  components: {
      ProductList,
      RewardsIcon
  },
})
export default class Marketplace extends Vue {
    quipuswapLink = 'https://quipuswap.com/swap/tez-KT1GorwGk5WXLUc3sEWrmPLQBSekmYrtz1sn'

    href3Route = 'https://3route.io/swap/tcoin/xtz'

    soldSearch: SoldSearch = 'Available'
    contractSearch = 'All'

    itemSoldSearch: {text: SoldSearch, value: SoldSearch}[] = [
        {
            text: 'Available',
            value: 'Available'
        },
        {
            text: 'Sold out',
            value: 'Sold out'
        },
        {
            text: 'All',
            value: 'All'
        }
    ]

    contracts = [
        {
            text: 'All',
            value: 'All'
        },
        {
            text: 'Trooperz',
            value: this.$config?.tokenContractAddress
        },
        {
            text: 'Comics',
            value: this.$config?.comicsTokenAddress
        },
        {
            text: 'Artwork',
            value: this.$config?.contractArtwork
        },
        {
            text: 'Items',
            value: this.$config?.contractBooster
        }
    ]

    mounted () {
        this.getQueryParamsValues()
    }

    getQueryParamsValues () {
        if (this.$route.query.soldSearch && isSoldSearch(this.$route.query.soldSearch)) {
            this.soldSearch = this.$route.query.soldSearch
        }

        if (this.$route.query.contractSearch && typeof this.$route.query.contractSearch === 'string') {
            this.contractSearch = this.$route.query.contractSearch
        }
    }

    updateQueryParams () {
        this.$router.replace({name: 'Marketplace', query: { soldSearch: this.soldSearch, contractSearch: this.contractSearch}})
    }

    @Watch('soldSearch')
    onChangeSoldSearch () {
        this.updateQueryParams()
    }

    @Watch('contractSearch')
    onChangeContractSearch () {
        this.updateQueryParams()
    }

}
