



























































import { MagazineInfo, MagazineInfoMulti } from '@/type';
import { convertValueFromContract, getIpfsPath } from '@/utils/utilities';
import { isMagazineMulti, isMagazine } from '@/type_helper';
import axios from 'axios';
import { Component, Vue, Prop } from 'vue-property-decorator';
import MintButton from '@/components/MintButton.vue'
import OperationLoadingDialog from '@/components/OperationLoadingDialog.vue'
import RewardsIcon from '@/components/RewardsIcon.vue'
import { mixins } from 'vue-class-component';
import { OpKind, ParamsWithKind } from '@taquito/taquito';
import WalletMixin from '../mixins/WalletMixin';
import { Config } from 'config/config_type';
import BigNumber from 'bignumber.js';

@Component({
  components: {
      MintButton,
      RewardsIcon,
      OperationLoadingDialog
  },
})
export default class ProductBox extends mixins(WalletMixin)  {
    @Prop()
    magazineInfo!: MagazineInfo | MagazineInfoMulti

    @Prop({default: false})
    isComingSoon!: boolean

    @Prop()
    magazineId!: number

    @Prop({ default: false })
    buyMany!: boolean

    tokenInfo: {name: string, image: string, displayUri?: string, artifactUri?: string} | null = null

    nbBuy = 1

    itemsBuy: {text: string, value: number}[] = []
    
    tokenInfoComingSoon = {
        name: 'Coming soon...',
        image: require('../assets/Logo_trooperzclub.png')
    }


    opeHash?: string | null = null
    activeDialog = false
    loading = false
    success = false

    created () {
        this.generateItemsBuy()
    }

    async mounted () {
        this.getTokenInfo()
    }

    async getTokenInfo () {
        if (this.isComingSoon) {
            this.tokenInfo = this.tokenInfoComingSoon
        } else {
            let ipfsPath = getIpfsPath(this.magazineInfo.token_info)
            const tokenInfoJson = (await axios.get(ipfsPath)).data
            this.tokenInfo = tokenInfoJson
        }
    }

    generateItemsBuy () {
        let itemsBuy = []
        for (let i = 1; i <= 10; i++) {
            itemsBuy.push({
                text: `${i}. ${this.calcTcoinAmount(i, true)} TCOIN`,
                value: i
            })
        }
        this.itemsBuy = itemsBuy
    }

    proxyGetIpfsPath (str: string) {
        return getIpfsPath(str)
    }

    get tokenInfoImage () {
        if (this.tokenInfo) {
            if (this.isComingSoon || isMagazine(this.magazineInfo)) {
                return this.tokenInfo?.image
            } else if (isMagazineMulti(this.magazineInfo)) {
                return this.tokenInfo?.artifactUri 
            }
            console.warn('Can not detect magazine type')
        }
        return null
    }

    calcTcoinAmount (amount: number, toReal = false) {
        let value = new BigNumber(this.magazineInfo.price).multipliedBy(amount)
        return toReal && this.$config ? value.dividedBy(new BigNumber(10).pow(this.$config.nbDigitTcoin)) : value
    }

    async mint () {
        const activeAccount = await this.$wallet.client.getActiveAccount();
        const config = this.$config
        let error = false
        let currentAddress = null

        if (!config) {
            throw Error('Can not find config!')
        }

        if (!activeAccount) {
            let permissions = await this.requestPermissions()
            currentAddress = permissions?.address
        } else {
            currentAddress = activeAccount.address
        }
        const tcoinContract = await this.$tezos.wallet.at(config.tcoinAddress)
        const marketplaceAddress = this.getMarketplaceContract(config)
        if (!marketplaceAddress) {
            throw Error('Can not find marketplace address!')
        }
        const marketplaceContract = await this.$tezos.wallet.at(marketplaceAddress)
        const amount = this.buyMany ? this.nbBuy : 1 
        let tcoinAmount = this.calcTcoinAmount(amount)

        const approveParams = {
            spender: marketplaceAddress,
            amount: tcoinAmount
        }


        const buyParams = {
            amount: amount,
            magazine_id: Number(this.magazineId)
        }

        let result = null
        // launch batch
        try {
            const batch = await this.$tezos.wallet.batch()
            .withContractCall(tcoinContract.methods.approve(approveParams.spender, approveParams.amount))
            .withContractCall(marketplaceContract.methods.buy_nfts(buyParams.amount, buyParams.magazine_id))

            this.activeDialog = true
            this.loading = true
            this.success = false

            const batchOp = await batch.send();
            console.log('Operation hash:', batchOp.opHash);
            this.opeHash = batchOp.opHash
            const result = await batchOp.confirmation();
        } catch (err) {
            this.activeDialog = false
            console.warn("Got error:", err);
            error = true
        }


        this.loading = false
        this.success = !error
        return result
    }

    refreshProducts () {
        this.getTokenInfo()
    }

    getMarketplaceContract (config: Config) {
        if (isMagazine(this.magazineInfo)) {
            return config?.trooperzMarketplaceAddress
        } else if (isMagazineMulti(this.magazineInfo)) {
            return config?.marketplaceMulti
        }
        console.warn('Can not detect magazine type')
        return null
    }

    get nbItem () {
        if (isMagazine(this.magazineInfo)) {
            return this.magazineInfo && this.magazineInfo.token_ids 
            ? this.magazineInfo.token_ids.length
            : 0
        } else if (isMagazineMulti(this.magazineInfo)) {
            return this.magazineInfo && this.magazineInfo.amount 
            ? Number(this.magazineInfo.amount)
            : 0
        }
        console.warn('Can not detect magazine type')
        return 0

    }

    get price () {
        return this.magazineInfo && this.magazineInfo.price && this.$config?.nbDigitTcoin
        ? convertValueFromContract(Number(this.magazineInfo.price), this.$config.nbDigitTcoin)
        : ''
    }

    get btnIsDisabled () {
        return !this.nbItem
    }
}
