





















import { Component, Vue, Prop } from 'vue-property-decorator';
import ProductBox from '@/components/ProductBox.vue'
import { getContractStorage } from '@/apiCaller/tzktApi';
import { BaseMagazineInfo, MagazineInfo, MagazineInfoMulti, SoldSearch } from '@/type';

@Component({
  components: {
      ProductBox
  },
})
export default class ProductList extends Vue {
    @Prop()
    soldSearch!: SoldSearch

    @Prop()
    contractSearch!: string

    magazineInfo: (MagazineInfo & {magazineId: number})[] = []
    magazineInfoMulti: (MagazineInfoMulti & {magazineId: number})[] = []
    minimumNbItem = 4

    async mounted () {
        this.getData()
    }

    async getData () {
      const config = this.$config
      if (!config) {
          throw Error('No config !')
      }
      const result = await getContractStorage<Record<string, MagazineInfo>>(config.networkTzkt, config.trooperzMarketplaceAddress, ['magazine'])
      const resultMulti = await getContractStorage<Record<string, MagazineInfoMulti>>(config.networkTzkt, config.marketplaceMulti, ['magazine'])
      if (result) {
          this.magazineInfo = Object.keys(result).map((value, index) => {
            return {
                ...result[value as string],
                magazineId: Number(value)
            }
          })
      }

      if (resultMulti) {
         this.magazineInfoMulti = Object.keys(resultMulti).map((value, index) => {
            return {
                ...resultMulti[value],
                magazineId: Number(value)
            }
          })
      }
    }

    filterContract (item: BaseMagazineInfo) {
        return this.contractSearch === 'All' || this.contractSearch === item.contract
    }

    get magazineInfoFiltered () {
        return this.magazineInfo ? Object.values(this.magazineInfo)
        .filter(item => {
            if (this.soldSearch === 'Available') {
                return item.token_ids.length
            } else if (this.soldSearch === 'Sold out') {
                return !item.token_ids.length
            }
            return true
        }).filter(this.filterContract.bind(this)) : []
    }

    get magazineInfoMultiFiltered () {
        return this.magazineInfoMulti ? Object.values(this.magazineInfoMulti).filter(item => {
            if (this.soldSearch === 'Available') {
                return Number(item.amount)
            } else if (this.soldSearch === 'Sold out') {
                return !Number(item.amount)
            }
            return true
        }).filter(this.filterContract.bind(this)) : []
    }

    get noItems () {
        return !Object.values(this.magazineInfoFiltered).length && !Object.values(this.magazineInfoMultiFiltered).length
    }
}
